/* eslint-disable filenames/match-regex, filenames/match-exported */
import AlertContextProvider from '@actinc/dls/context/AlertContext/provider';
import THEME_ENCOURAGE_E4E from '@actinc/dls/styles/themeEncourageE4E';
import { ApolloProvider } from '@apollo/client';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { boolean } from 'boolean';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { FC, ReactElement, useEffect, useRef } from 'react';
import ReactWalkme, { IWalkme } from 'react-walkme';

import Analytics from '~/components/Analytics';
import SnackbarAlert from '~/components/SnackbarAlert';
import ENV from '~/constants/env';
import createApolloClient, { ApolloClientType } from '~/helpers/apollo/createApolloClient';
import createEmotionCache from '~/helpers/emotionCache/createEmotionCache';
import composeComponents from '~/helpers/react/composeComponents';
import useLogger from '~/hooks/useLogger';
import Maintenance from '~/pages/maintenance';
import DebugMenuProvider from '~/providers/DebugMenuProvider';
import { EducationPartnerSignalProviderComponent } from '~/providers/EducationPartnerSignal';
import { HomeDialogSignalProviderComponent } from '~/providers/HomeDialogSignal';
import { LastNavigationHistoryProvider } from '~/providers/LastNavigationHistoryProvider';
import { MyStudentsSelectionProviderComponent } from '~/providers/MyStudentsSelection';
import { ILogger } from '~/types';

const AppProviders = composeComponents([
  // Components added here will wrap every page of the app
  DebugMenuProvider,
  HomeDialogSignalProviderComponent,
  EducationPartnerSignalProviderComponent,
  LastNavigationHistoryProvider,
  MyStudentsSelectionProviderComponent,
]);

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    WalkMeInsightsAPI: {
      track: (event: string) => void;
    };
    walkme_variables: {
      user_role: string;
      user_uid: string;
    };
  }
}

const App: FC<AppProps> = ({ Component, pageProps }: AppProps): ReactElement<unknown> => {
  const apolloClient = useRef<ApolloClientType>();
  apolloClient.current ||= createApolloClient();
  const emotionCache = createEmotionCache();

  const logger: ILogger = useLogger();
  const maintenanceMode = boolean(ENV.MAINTENANCE_MODE);

  useEffect((): void => {
    // Remove the server-side injected CSS
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect((): void => {
    const initWalkme = (): void => {
      const walkmeInstance = ReactWalkme.walkme();

      if (walkmeInstance.error) {
        const walkmeId = ENV.WALKME_ID as string;
        const walkmeTest = boolean(ENV.WALKME_TEST);
        const walkmeDebug = boolean(ENV.WALKME_DEBUG);

        const walkMeCallback = (): void => {
          const initializedWalkme = ReactWalkme.walkme() as IWalkme;
          const envId = initializedWalkme.getEnvId();

          if (walkmeDebug && envId) {
            logger.debug('WalkMe initialized correctly with env ID: ', envId);
          }
        };

        ReactWalkme.initialize(walkmeId, walkmeTest, walkmeDebug, walkMeCallback);
      }
    };
    if (!maintenanceMode) {
      initWalkme();
    }
  }, [logger, maintenanceMode]);

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
      </Head>

      <CacheProvider value={emotionCache}>
        <Analytics>
          <ApolloProvider client={apolloClient.current}>
            <ThemeProvider theme={THEME_ENCOURAGE_E4E}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AppProviders>
                  <AlertContextProvider
                    Components={{
                      default: SnackbarAlert,
                      error: SnackbarAlert,
                      info: SnackbarAlert,
                      success: SnackbarAlert,
                      warning: SnackbarAlert,
                    }}
                  >
                    {maintenanceMode ? <Maintenance /> : <Component {...pageProps} />}
                  </AlertContextProvider>
                </AppProviders>
              </LocalizationProvider>
            </ThemeProvider>
          </ApolloProvider>
        </Analytics>
      </CacheProvider>
    </>
  );
};

export default App;
