/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HeadersLink } from '@encoura/apollo-rest-utils';
import { RestLink } from 'apollo-link-rest';

import ENV from '~/constants/env';
import NEXT from '~/constants/next';
import useToken from '~/hooks/useToken';

import sentryApolloClientError from '../SentryErrors/sentryApolloClientError';

export type ApolloClientType = ApolloClient<NormalizedCacheObject>;

type ApiName = 'federatedGraph' | undefined;

const defaultMerge = (existing: unknown[] | undefined, incoming: unknown[]): unknown[] => {
  return [...(existing ?? []), ...incoming];
};

export const cache = (): InMemoryCache =>
  new InMemoryCache({
    addTypename: true,
    possibleTypes: {
      School: ['SearchResponse', 'HighSchoolsByHsidResponse'],
      Student: ['StudentsByStudentkeyResponse', 'StudentProfileResult'],
      StudentList: ['EncouraEducator'],
      User: ['UsersByUidResponse'],
    },
    typePolicies: {
      School: {
        fields: {
          permissions: { merge: true },
          state: { merge: true },
        },
        keyFields: ['hs_id'],
      },
      Student: {
        fields: {
          tasks: { merge: defaultMerge },
        },
        keyFields: ['student_key'],
      },
      StudentList: {
        keyFields: ['contact_id', 'studentsPaginated', ['students']],
      },
      User: {
        keyFields: ['uid'],
        merge: true,
      },
    },
  });

const createApolloClient = (apiBaseUrl?: string): ApolloClientType => {
  const encourageServiceAuthLink = (apiKey?: string): ApolloLink => {
    return setContext((_, { headers }): Record<string, unknown> => {
      const bearerToken = useToken();

      return {
        headers: {
          ...headers,
          authorization: bearerToken ? `JWT ${bearerToken}` : '',
          'x-api-key': apiKey,
        },
      };
    });
  };

  const federatedGraphAuthLink = (): ApolloLink => {
    return setContext((_, { headers }): Record<string, unknown> => {
      const bearerToken = useToken();

      return {
        headers: {
          ...headers,
          'x-legacy-token': bearerToken ? `JWT ${bearerToken}` : '',
        },
      };
    });
  };

  const headersLink = new HeadersLink();

  const restLink = new RestLink({
    uri: apiBaseUrl || ENV.API_BASE_URL,
  });
  const federateGraphLink = new HttpLink({
    uri: (): string => process.env.DEBUG_API_ENDPOINT_GRAPHQL || ENV.API_BASE_URL_FEDERATED_GRAPH || '',
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }): Observable<FetchResult> | undefined => {
    if (networkError) {
      sentryApolloClientError(networkError, operation);
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations?.map(l => `Column: ${l.column} Line: ${l.line}`).join(', ') ?? 'unknown'}, Path: ${
            path?.join(', ') ?? 'unknown'
          }`,
        );
        return null;
      });
    }

    return undefined;
  });

  const encourageServiceLink = from([encourageServiceAuthLink(ENV.API_KEY), errorLink, headersLink, restLink]);
  const federatedGraphLink = from([federatedGraphAuthLink(), errorLink, federateGraphLink]);

  const apiName: ApiName = 'federatedGraph';

  const links = from([
    ApolloLink.split(operation => operation.getContext().apiName === apiName, federatedGraphLink, encourageServiceLink),
  ]);

  return new ApolloClient({
    cache: cache(),
    connectToDevTools: !NEXT.IS_SERVER,
    link: links,
  });
};

export default createApolloClient;
