import dynamic from 'next/dynamic';
import React from 'react';

import getEnvironment from '~/helpers/getEnvironment';
import { DebugFlagsProvider } from '~/hooks/useDebugMenu';

const DebugMenuProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const displayDebugMenu = getEnvironment() !== 'production';

  const DebugMenuComponent = React.useMemo(
    () => (displayDebugMenu ? dynamic(() => import('~/components/DebugMenu'), { ssr: false }) : null),
    [displayDebugMenu],
  );

  return (
    <DebugFlagsProvider>
      {children}
      {DebugMenuComponent && <DebugMenuComponent />}
    </DebugFlagsProvider>
  );
};

export default DebugMenuProvider;
